<template>
  <ConfirmDialog></ConfirmDialog>
  <TabMenu :model="tabs" class="mb-5" />
  <router-view />
</template>

<script>
export default {
  name: "Settings",
  data() {
    return {
      tabs: [
        {
          label: this.$t("settings.templates"),
          to: "/settings/templates",
          icon: "mdi mdi-format-list-numbered",
        },
        {
          label: this.$t("settings.countries"),
          to: "/settings/countries",
          icon: "mdi mdi-earth-plus",
        },
        {
          label: this.$t("settings.cities"),
          to: "/settings/cities",
          icon: "mdi mdi-earth-plus",
        },
        {
          label: this.$t("settings.doy"),
          to: "/settings/doy",
          icon: "mdi mdi-office-building-marker",
        },
        {
          label: this.$t("settings.companyType"),
          to: "/settings/companyType",
          class: "white-space-nowrap",
          icon: "mdi mdi-store-plus",
        },
        {
          label: this.$t("settings.notifications"),
          to: "/settings/notifications",
          icon: "mdi mdi-bell-plus",
        },
        {
          label: this.$t("settings.actions"),
          to: "/settings/actions",
          icon: "mdi mdi-clock-plus",
        },
        {
          label: this.$t("settings.variables"),
          to: "/settings/variables",
          icon: "mdi mdi-alarm-light-outline",
        },
      ],
    };
  },
  mounted() {
    let selectedTab = document.querySelector(".p-highlight");
    selectedTab.scrollIntoView(false);
    this.$action("settings-page-view");
  },
};
</script>
